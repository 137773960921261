import Glide from '@glidejs/glide';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.glide') != null) {
    new Glide('.glide', {
      startsAt: 0,
      autoplay: 3000,
      type: 'carousel',
    }).mount();

    const sliders = document.querySelectorAll('.glide.is-multi');

    for (let i = 0; i < sliders.length; i += 1) {
      const glide = new Glide(sliders[i], {
        type: 'carousel',
        perView: 1,
        gap: 10,
        autoplay: false,
      });

      glide.mount();
    }
  }
});
